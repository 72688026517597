@import '../../styles/variables.scss';

.modal {
  .modal-card {
    .modal-card-head {
      border-bottom: none;
      background-color: $white;

      button.is-modal-close {
        position: absolute;
        right: $modal-card-head-padding;
      }
    }

    .modal-card-body {
      padding-top: 0;
    }

    .modal-card-foot {
      border-top: none;
      background-color: $white;
      padding-top: 0;

      .buttons {
        &.is-right {
          width: 100%;
        }
      }
    }
  }
}

@include tablet-only {
  .modal {
    .modal-card {
      width: 720px;
    }
  }
}

@include desktop {
  .modal {
    .modal-card {
      width: 800px;
    }
  }
}
